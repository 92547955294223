<!-- 客户看的需求详情 这里面没有quotation 列表，与quotationDetail 页面重合 ，在与quotationDetail页面判断是否有quotation进行显示隐藏就可以 暂时不用-->

<template>
  <div class="container_requirement">
    <div class="requirement">
      <div class="requirement_left">
        <img class="requirement_left_mainImage" src="../../assets/images/product/big_product.png" alt="">
      </div>
      <div class="requirement_right">
        <div class="requirement_right_titleBox">
          <div class="requirement_right_titleBox_title">Box - gift box</div>
          <div class="requirement_right_titleBox_quantity"><span>Quantity</span>10000</div>
        </div>

        <!-- 详情 -->
        <div class="requirement_right_details">
          <div class="requirement_right_details_list" v-for="(item, index) in items" :key="index">
            <div class="requirement_right_details_list_title" v-for="(value, key) in item" :key="key">{{ key }}
            </div>
            <div class="requirement_right_details_list_value" v-for="(value, key) in item" :key="key">{{ value
            }}</div>
          </div>
          <div class="requirement_right_details_list file">
            <div class="file_left">
              <div class="requirement_right_details_list_title">Original Artwork</div>
              <div class="requirement_right_details_list_value">Design draft.ai</div>
            </div>
            <div class="file_download">Download</div>
          </div>
        </div>

      </div>
    </div>
    <Foot />
  </div>
</template>

<script setup name="RequirementDetailsCustomer">
import { reactive, ref, toRefs, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Foot from '../../components/Foot.vue'

// 获取路由参数
const router = useRouter()
const route = useRoute()
const requirementId = route.query.id
console.log('主页传递到详情需求id：', requirementId)

const items = ref([
  { 'Product Name': 'Box - gift box' },
  { 'Product size': '23mm * 20mm * 40mm' },
  { Material: 'Lvory board' },
  { Color: 'Pantone（17-4911 TPX）' },
  { 'Proess Requirments': 'Concave-convex' },
  { 'Other specific requirments': '-' },
  { 'Overall Budget': '50000.00 EUR' },
  { 'Leadtime Requirment': 'May 1, 2023' },
  { 'Delivery address': 'Seaview Holiday Apartments Ireland' },
  { 'Sample requirment': 'Yes' }
])

</script>

<style lang="scss" scoped>
.container_requirement {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .requirement {
    width: 1600px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    line-height: 1;
    text-align: left;

    &_left {
      // position: fixed;

      &_mainImage {
        width: 500px;
        height: 500px;
        object-fit: cover;
        border-radius: 6px;
        overflow: hidden;
      }
    }

    &_right {
      width: 66%;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      overflow: hidden;
      // margin-left: 410px;

      &_titleBox {
        width: 100%;
        border: 1px solid rgba(255, 255, 255, 1);
        box-sizing: border-box;
        border-bottom: none;
        background: linear-gradient(180deg, rgba(39, 174, 96, 0.1) 0%, rgba(39, 174, 96, 0) 100%);
        display: flex;
        justify-content: space-between;

        &_title {
          font-size: 24px;
          color: rgba(24, 24, 24, 1);
          font-weight: 700;
          margin: 40px 0 40px 50px;
        }

        &_quantity {
          font-size: 16px;
          color: rgba(24, 24, 24, 1);
          font-weight: 600;
          margin: 40px 50px 40px 0;

          span {
            color: rgba(173, 173, 173, 1);
            font-weight: 300;
            margin-right: 10px;
          }
        }
      }

      &_details {
        margin-top: -10px;

        &_list {
          display: flex;
          margin: 0 50px;
          border-bottom: 1px solid rgba(240, 240, 240, 1);
          padding: 20px 0;

          &_title {
            font-size: 14px;
            color: rgba(150, 150, 150, 1);
            font-weight: 300;
            line-height: 16px;
            width: 180px;
          }

          &_value {
            font-size: 16px;
            color: rgba(24, 24, 24, 1);
          }
        }

        .file {
          margin-bottom: 80px;
          display: flex;
          justify-content: space-between;

          &_left {
            display: flex;
            width: 500px;
          }

          &_download {
            font-size: 16px;
            color: rgba(39, 174, 96, 1);
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
